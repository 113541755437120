import React from 'react';
import { Table, Button } from 'react-bootstrap';
import '../App.css';

const Affiliates = () => {
  const data = [
    { company: "Apex Trader Funding", discount: "80%", resetDiscount: "0%", accFee: "0%", signUpLink: "https://bit.ly/ATFPICKLE", coupon: "PICKLE", dealEnd: "07/31/2024", extra: "Pass evals in 1 day. $150K, $250K and<br />  $300K evals are all $40! Lifetime activation <br />for these are also $85." },
    { company: "Elite Trader Funding", discount: "50%", resetDiscount: "0%", accFee: "0%", signUpLink: "https://tinyurl.com/Picklesir", coupon: "FTJULY <br />or<br /> R72224", dealEnd: "08/01/2024", extra: "Enjoy 50% off 100k FT,<br /> Plus One Day to Pass Add-on" },
    { company: "TopStep", discount: "20%", resetDiscount: "0%", accFee: "0%", signUpLink: "https://tinyurl.com/SIRPICKLE", coupon: "👈 Click the link", dealEnd: "On-going", extra: "Earn a funded account on TradingView" },
    { company: "TradeDay", discount: "20%", resetDiscount: "0%", accFee: "0%", signUpLink: "https://members.tradeday.com/?aff=TD_SirPickle", coupon: "PICKLE", dealEnd: "On-going", extra: "Discount applied when you click the link!" },
    { company: "MyFundedFutures", discount: "25%", resetDiscount: "0%", accFee: "0%", signUpLink: "https://myfundedfutures.com/?ref=965", coupon: "PICKLE", dealEnd: "On-going", extra: "Valid for just the next 24 hours, <br />get 25% OFF ALL Expert evals and resets! " },
  ];

  const buttonStyle = {
    backgroundColor: '#8CB65A',
    borderColor: '#8CB65A',
    color: 'white'
  };

  const buttonHoverStyle = {
    backgroundColor: 'black',
    borderColor: 'black',
    color: 'white'
  };

  return (
    <div>
      <div className="affiliates-table-wrapper">
      <h1><b>Prop Deals at a Glance</b></h1>
      <p>Ready to fund your profitable strategy?</p>
        <Table striped bordered hover className="affiliates-table-two">
          <thead>
            <tr>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Company</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Discount</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Reset Discount</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Activation Fee Discount</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Sign Up Link</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Coupon Code</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Deal Ends</th>
              <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Extra</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.company}</td>
                <td>{item.discount}</td>
                <td>{item.resetDiscount}</td>
                <td>{item.accFee}</td>
                <td><Button 
                    href={item.signUpLink} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={buttonStyle}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor;
                      e.currentTarget.style.borderColor = buttonHoverStyle.borderColor;
                      e.currentTarget.style.color = buttonHoverStyle.color;
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor;
                      e.currentTarget.style.borderColor = buttonStyle.borderColor;
                      e.currentTarget.style.color = buttonStyle.color;
                    }}
                  >
                    Sign Up
                  </Button></td>
                <td dangerouslySetInnerHTML={{ __html: item.coupon }}></td>
                <td>{item.dealEnd}</td>
                <td dangerouslySetInnerHTML={{ __html: item.extra }}></td>
              </tr>
            ))}
          </tbody>
        </Table>
        <br></br>
      </div>
    </div>
  );
};

export default Affiliates;
